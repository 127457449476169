var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.clientData)?_c('b-card',[_c('status-alert',{attrs:{"is-success":_vm.isSuccess,"error-data":_vm.errorData,"success-msg":_vm.successMsg}}),_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"6"}},[_c('validation-observer',{ref:"formClient"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":_vm.$t('Client/Company Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Client/Company Name","label-for":"client-name"}},[_c('b-form-input',{attrs:{"id":"client-name","size":"lg","placeholder":"Enter a client name...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.name),callback:function ($$v) {_vm.$set(_vm.clientData, "name", $$v)},expression:"clientData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3632979736)}),_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","size":"lg","placeholder":"Enter a email...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.email),callback:function ($$v) {_vm.$set(_vm.clientData, "email", $$v)},expression:"clientData.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,1870759067)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Billing address","label-for":"billing-address"}},[_c('b-form-textarea',{attrs:{"id":"billing-address","size":"lg","rows":"4","placeholder":"Enter a description"},model:{value:(_vm.clientData.billing_address),callback:function ($$v) {_vm.$set(_vm.clientData, "billing_address", $$v)},expression:"clientData.billing_address"}})],1)],1),_c('validation-provider',{attrs:{"name":_vm.$t('Contact name'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Contact name","label-for":"contact-name"}},[_c('b-form-input',{attrs:{"id":"contact-name","size":"lg","placeholder":"Enter a contact name...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.contact_name),callback:function ($$v) {_vm.$set(_vm.clientData, "contact_name", $$v)},expression:"clientData.contact_name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3427326223)}),_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","size":"lg","placeholder":"Enter a phone...","type":"tel","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.phone),callback:function ($$v) {_vm.$set(_vm.clientData, "phone", $$v)},expression:"clientData.phone"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,3916321016)}),_c('validation-provider',{attrs:{"name":_vm.$t('Mobile'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile","label-for":"mobile"}},[_c('b-form-input',{attrs:{"id":"mobile","size":"lg","placeholder":"Enter a mobile number...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.mobile),callback:function ($$v) {_vm.$set(_vm.clientData, "mobile", $$v)},expression:"clientData.mobile"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,2147132148)}),_c('validation-provider',{attrs:{"name":_vm.$t('Website'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","size":"lg","placeholder":"Enter a website...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.website),callback:function ($$v) {_vm.$set(_vm.clientData, "website", $$v)},expression:"clientData.website"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,2914338444)}),_c('validation-provider',{attrs:{"name":_vm.$t('Tax number'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tax number","label-for":"tax-number"}},[_c('b-form-input',{attrs:{"id":"tax-number","size":"lg","placeholder":"Enter a tax number...","state":errors.length > 0 ? false:null},model:{value:(_vm.clientData.tax_number),callback:function ($$v) {_vm.$set(_vm.clientData, "tax_number", $$v)},expression:"clientData.tax_number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,4206643302)}),_c('validation-provider',{attrs:{"name":_vm.$t('Notesr'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","size":"lg","rows":"4","placeholder":"Enter a description"},model:{value:(_vm.clientData.notes),callback:function ($$v) {_vm.$set(_vm.clientData, "notes", $$v)},expression:"clientData.notes"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)]}}],null,false,1521196781)}),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.saveData}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }